.ant-input-affix-wrapper {
  padding: 10px 12px;
  box-shadow: none !important;
}

.ant-input {
  padding: 10px 12px;
  color: color(text-color);
  background: white;
  border: 1px solid color(border-default);
  box-shadow: none !important;

  @include input-placeholder {
    color: #bfbfbf;
  }

  &:hover,
  &:focus {
    border: 1px solid color(primary);
    box-shadow: none;
  }
}
.ant-input-password-icon.anticon {
  color: color(text-grey);
  svg {
    path {
      fill: color(text-grey);
    }
  }
  &.anticon-eye {
    svg {
      path {
        &:nth-child(2) {
          fill: transparent;
        }
      }
    }
  }
  &:hover {
    color: color(text-grey);
  }
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid color(primary);
  box-shadow: none;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  background: #4d4d4d;
  border: 1px solid transparent;
  color: color(text-grey);
}

.ant-form-item {
  margin-bottom: 16px;
  .ant-form-item-label {
    padding-bottom: 0;
    margin-bottom: 5px;
  }
  label {
    color: color(text-grey);
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 4px;
  }
  .ant-input-affix-wrapper {
    border: 1px solid color(border-default);
    background: white;

    .ant-input {
      background: white;

      &:hover,
      &:focus {
        border: none;
      }
    }
  }
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background: white;
    box-shadow: none;
  }
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background: white;
  }
  .ant-rec-form-item-value {
    .value {
      font-weight: 700;
      color: black;
    }
    .sub-value {
      font-size: 13px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
.ant-checkbox-inner {
  border-radius: 2px;
}
.ant-spin-nested-loading {
  .ant-spin-container {
    &::after {
      background: color(primary);
      border-radius: 8px;
    }
    &.ant-spin-blur {
      &::after {
        opacity: 0.05;
      }
    }
  }
}

.ant-select-multiple .ant-select-selector {
  padding: 7px 4px;
}
.link-video-table {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  max-width: 400px;
  text-overflow: ellipsis;
}
.react-pdf {
  &__Page__canvas {
    width: 100% !important;
    height: 100% !important;
  }

  &__Page__annotations {
    width: 100% !important;
    height: 100% !important;
  }
}

.no-wrap {
  white-space: nowrap;
}
