.rec-vmo-layout {
  &__content {
    background-color: #f2f2f2;
    padding: 24px;
    margin-left: 240px;
    transition: ease all 0.4s;
    margin-top: 70px;
    min-height: calc(100vh - 70px);
  }
}
.ant-layout {
  background-color: color(background-secondary);
}
.ant-layout {
  background: color(background-secondary);
}
.rec-vmo-page {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .info {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 24px;
    color: color(text-color);
  }
  &__content {
    padding: 16px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    &__main {
      margin-top: 24px;
      height: 100%;
    }
  }
  table {
    th {
      &:last-child {
        width: 100px;
      }
    }
  }
}

.rec-link-primary {
  font-weight: 600;
  color: black !important;
  &:hover {
    cursor: pointer;
  }
}
.rec-link {
  color: color(primary) !important;
  &:hover {
    cursor: pointer;
  }
}
