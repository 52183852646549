.rec-vmo-header {
  height: 70px;
  background-color: color(background-primary);
  padding-left: 14px;
  padding-right: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1000;
  &__left {
    display: flex;
    align-items: center;
  }
  &__toggle {
    color: white;
    font-size: 18px;
    &:hover,
    &:focus {
      color: white;
    }
    @include respond-down(sm) {
      display: none;
    }
    &.mobile {
      display: none;
      @include respond-down(sm) {
        display: block;
        margin-left: 32px;
        padding: 0;
        height: 28px;
      }
    }
  }
  &__logo {
    width: 210px;
  }
}

.rec-vmo-notification {
  display: flex;
  align-items: center;
  &__icon {
    color: color(text-default);
  }
}
