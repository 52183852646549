.rec-vmo-form {
  &__forgot-password {
    font-weight: 600;
    font-size: 14px;
    text-decoration: underline;
  }
  &__item {
    display: flex;
    justify-content: center;
    &.other-method {
      margin-bottom: 12px;
      .ant-row {
        width: 100%;
      }
      .other-method-login {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        span {
          color: white;
          display: flex;
          padding: 0 24px;
          background-color: color(background-primary);
          z-index: 2;
        }
        &::before {
          position: absolute;
          top: 10px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: color(border-primary);
          content: "";
        }
      }
    }
    .create-or-login {
      display: flex;
      align-items: center;
      span {
        color: #cccccc;
        font-size: 14px;
        font-weight: 400;
        padding-right: 4px;
      }
    }

    .other-login {
      display: flex;
      align-items: center;
    }
  }
  &__button-other-method {
    height: 40px;
    padding: 0;
    width: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }
  &__submit {
    margin-top: 8px;
    height: 44px;
    padding-left: 40px;
    padding-right: 40px;
    font-weight: 600;
    font-size: 14px;
    &:hover,
    &:focus {
      color: color(text-color);
    }
  }
  .error-message {
    color: color(error-primary);
    margin-bottom: 16px;
  }

  .ant-form-item-with-help .ant-form-item-explain {
    margin-top: 4px;
    margin-bottom: 24px;
  }
  .actions {
    margin-top: auto;
    padding: 16px 0;
    border-top: 1px solid #d9d9d9;
    position: sticky;
    bottom: 0;
    z-index: 20;
    background-color: white;
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 8px;
    }
  }
}

.rec-vmo-form-item {
  display: flex;
  flex-direction: column;
  label {
    font-weight: 600;
    font-size: 14px;
    color: color(tex-label);
    line-height: 24px;
    margin-bottom: 4px;
  }
}
.form-view-only {
  .ant-form-item {
    padding-bottom: 0;
    margin-bottom: 16px;
    height: 100%;
    .ant-form-item-label {
      padding-bottom: 0;
      margin-bottom: 0;
    }
    label {
      color: color(text-grey);
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0;
    }
    .ant-input-affix-wrapper {
      border: 1px solid color(border-default);
      background: white;

      .ant-input {
        background: white;

        &:hover,
        &:focus {
          border: none;
        }
      }
    }
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
      background: white;
      box-shadow: none;
    }
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
      background: white;
    }
    .ant-rec-form-item-value {
      .value {
        font-weight: 700;
        color: black;
      }
      .sub-value {
        font-size: 13px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

.form-required {
  .ant-form-item-label {
    position: relative;
    width: fit-content;
    padding-right: 12px;
    &::after {
      position: absolute;
      top: 2px;
      right: 2px;
      content: "*";
      color: red;
    }
  }
}

.dropdown-action {
  padding-left: 4px;
  padding-right: 4px;
  svg {
    width: 20px;
    height: 20px;
  }
  &__menu {
    .ant-dropdown-menu-item {
      button {
        &:hover,
        &:focus {
          background-color: transparent;
        }
      }
    }
  }
}
