.rec-dialog-job {
  .ant-drawer-content-wrapper {
    width: 900px !important;
    max-width: 95vw !important;
    .ant-drawer-header {
      background-color: #f2f2f2;
    }
    .ant-drawer-header-title {
      .ant-drawer-title {
        order: 1;
        font-size: 20px;
        font-weight: 700;
      }
      .ant-drawer-close {
        order: 2;
        margin-right: 0;
      }
    }
    .ant-drawer-body {
      padding: 16px 24px 0 24px;
      .rec-vmo-form {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }
  }
}
