/* ==========================================================================
   # START VARIABLE SETTING
   ==========================================================================
*/

$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1280px,
  xl: 1400px,
  xxl: 1600px,
  xxxl: 1920px,
);

$colors: (
  primary: #f37021,
  background-primary: #252525,
  background-secondary: #f2f2f2,
  text-label: "#CCCCCC",
  text-color: "#2E260A",
  text-grey: #808080,
  text-default: "#CCCCCC",
  text-pagination: "#616161",
  border-primary: "#333333",
  green-primary: #30d158,
  error-primary: #e9453a,
  border-default: #d9d9d9,
);
$colors-dark: (
  primary: #ffc900,
  background-primary: #252525,
  background-secondary: #1a1a1a,
);

:root {
  /*Begin color*/
  @each $name, $color in $colors {
    --color-#{$name}: #{$color};
  }

  /*End color*/
}

[data-theme="dark"] {
  @each $name, $color in $colors-dark {
    --color-#{$name}: #{$color};
  }
}

//End variable root
/* ==========================================================================
   # END VARIABLE SETTING
   ==========================================================================
*/
