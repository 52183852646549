.rec-vmo-modal {
  .ant-modal-body {
    background-color: color(background-primary);
    border-radius: 8px;
    padding: 40px 24px;
  }
  .ant-modal-content {
    background-color: transparent;
  }
  &__content {
    &.center {
      text-align: center;
    }
    .box-icon {
      margin-bottom: 32px;
      .icon {
        font-size: 60px;
        &.success {
          color: color(green-primary);
        }
        &.error {
          color: color(error-primary);
        }
      }
    }
    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: white;
      margin-bottom: 8px;
      &.center {
        text-align: center;
      }
    }
    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: color(text-default);
    }
  }
  &__footer {
    .actions {
      margin-top: 32px;
      &.center {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .submit {
        height: 44px;
        padding-left: 44px;
        padding-right: 44px;
        font-size: 14px;
        font-weight: 700;
        color: color(text-color);
      }
    }
  }
  &.term {
    width: 80vw !important;
    .description {
      max-height: calc(100vh - 250px);
      overflow-y: auto;
      @include customScrollTrack(8px, color(background-secondary), 4px);
    }
  }
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.33);
  backdrop-filter: blur(20px);
}
