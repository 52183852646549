.authenticate {
  position: relative;
  min-height: 100vh;
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 0.9;
  }
  &__page {
    padding: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow-y: auto;
    @include customScrollTrack(8px, color(background-secondary), 4px);
  }
  &__form {
    width: 502px;
    padding: 40px 24px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    &__top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: auto;
        height: 42px;
        object-fit: cover;
      }
    }
  }
  &__title {
    margin-top: 24px;
    font-weight: 700;
    font-size: 28px;
    color: color(text-color);
    margin-bottom: 32px;
  }
}
