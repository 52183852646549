.new-candidate {
  .ant-table-cell {
    background-color: rgba(243, 112, 33, 0.1) !important;
  }
}

.candidates-page {
  .ant-table-thead {
    th {
      &:nth-child(2) {
        min-width: 250px;
      }
      &:nth-child(5) {
        min-width: 250px;
      }
    }
  }
}
