.rec-vmo-sidebar {
  background-color: white;
  padding: 16px;
  position: fixed;
  height: calc(100vh - 70px);
  z-index: 1000;
  top: 70px;
  overflow-y: auto;
  box-shadow: 12px 0 24px rgba(0, 0, 0, 0.1);
  @include customScrollTrack(8px, #f2f2f2, 4px);
  @include respond-down(sm) {
    right: -100vw;
    width: 300px !important;
    min-width: 300px !important;
    max-width: 300px !important;
    box-shadow: -4px 0 12px rgba(0, 0, 0, 0.4);
  }
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__top {
  }
  .rec-vmo-menu {
    background: transparent;
    border-right: none;
    .ant-menu-item {
      color: color(text-grey);
      display: flex;
      align-items: center;
      padding: 0 !important;
      padding-left: 13px !important;
      height: 44px;
      border-radius: 4px;
      margin-top: 16px;
      .ant-menu-item-icon {
        width: 20px;
        height: 20px;
        font-size: 20px !important;
        display: flex;
        align-items: center;
      }
      .ant-menu-title-content {
        font-size: 14px;
        font-weight: 600;
        color: color(text-color);
        a {
          color: color(text-color);
        }
      }
      &::after {
        border-right: none !important;
      }
      &:hover {
        background-color: color(primary);
        .ant-menu-item-icon {
          color: color(text-color);
        }
        .ant-menu-title-content {
          color: color(text-default);
          a {
            color: color(text-color);
          }
        }
      }
    }
    .ant-menu-item-selected {
      color: color(primary);
      background-color: transparent !important;
      &:hover {
        background-color: color(primary);
        .ant-menu-item-icon {
          color: color(primary);
        }
        .ant-menu-title-content {
          a {
            color: color(primary);
          }
        }
      }
      .ant-menu-title-content {
        a {
          color: color(primary);
        }
      }
    }
    &.ant-menu-inline-collapsed {
      .ant-menu-item {
        .ant-menu-title-content {
          width: 0;
          overflow: hidden;
          margin-left: 0;
        }
      }
    }
    &__group {
      &:not(:first-child) {
        margin-top: 16px;
      }
      &__title {
        font-size: 12px;
        font-weight: 400;
        color: color(text-grey);
        margin-bottom: 4px;
        display: flex;
        margin-left: 12px;
        height: 14px;
        align-items: center;
      }
      .ant-menu-item {
        margin-top: 0;
      }
    }
    &--bottom {
      .ant-menu-item {
        margin-bottom: 0;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  &__filter {
    width: 100% !important;
    margin-bottom: 16px;

    .ant-select-selector {
      height: 44px !important;
      display: flex;
      align-items: center;
      background-color: color(background-secondary) !important;
      border: 1px solid transparent !important;
      padding: 0 13px !important;
      &:hover {
        border: 1px solid color(primary) !important;
      }

      .ant-select-selection-search-input {
        height: 44px !important;
      }
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        font-weight: 700;
        color: color(text-default) !important;
      }
      .rec-vmo-sidebar__filter__item {
        font-weight: 700 !important;
      }
    }
    &.ant-select-focused {
      .ant-select-selector {
        border: 1px solid color(primary) !important;
        box-shadow: none !important;
      }
    }
    .ant-select-arrow {
      color: white;
    }
    &__popup {
      border-radius: 8px !important;
      background-color: color(background-secondary);
      width: 208px !important;
      .ant-select-item {
        padding: 10px 12px !important;
        color: color(text-default);
        &:hover {
          background-color: color(primary);
          color: color(text-color);
        }
        &:first-child {
          border-radius: 8px 8px 0 0;
        }
        &:last-child {
          border-radius: 0 0 8px 8px;
        }
      }
      .ant-select-item-option-selected {
        background-color: transparent !important;
        color: color(primary);
        &:hover {
          color: color(primary);
        }
        .rec-vmo-sidebar__filter__item {
          font-weight: 700 !important;
        }
      }
      .ant-select-item-option-active {
        background-color: color(primary) !important;
        color: color(text-color) !important;
      }
    }
    &__item {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      svg {
        margin-right: 10px;
      }
    }
  }
  &.ant-layout-sider-collapsed {
    .rec-vmo-menu {
      .ant-menu-item {
        padding: 0 !important;
        justify-content: center;
      }
    }
    .rec-vmo-menu__group__title {
      display: none;
    }
    .rec-vmo-sidebar__filter {
      .ant-select-arrow {
        display: none;
      }
    }
    + .rec-vmo-layout__content {
      margin-left: 80px;
    }
  }
}
