.input-normal {
  .ant-input-suffix {
    font-size: 20px;
  }
  &.success {
    border: 1px solid color(green-primary) !important;
    .ant-input-suffix {
      color: color(green-primary);
    }
  }
  &.error {
    border: 1px solid color(error-primary) !important;
    .ant-input-suffix {
      color: color(error-primary);
    }
  }
}

.rec-vmo-select {
  .ant-select-selector {
    height: 44px !important;
    display: flex;
    align-items: center;
    // background-color: color(background-secondary) !important;
    // border: 1px solid transparent !important;
    padding: 0 13px !important;
    &:hover {
      border: 1px solid color(primary) !important;
    }

    .ant-select-selection-search-input {
      height: 44px !important;
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      font-weight: 400;
      color: color(text-color) !important;
      font-size: 14px;
    }
  }
  &.ant-select-multiple {
    .ant-select-selector {
      height: auto !important;
    }
  }
  &.ant-select-focused {
    .ant-select-selector {
      border: 1px solid color(primary) !important;
      box-shadow: none !important;
    }
  }
  .ant-select-arrow {
    color: white;
  }
  &__popup {
    // background-color: color(background-secondary);
    padding: 0;
    .ant-select-item {
      padding: 10px 12px !important;
      color: color(text-color);
      &:hover {
        background-color: color(primary);
        color: color(text-color);
      }
      &:first-child {
        border-radius: 8px 8px 0 0;
      }
      &:last-child {
        border-radius: 0 0 8px 8px;
      }
    }
    .ant-select-item-option-selected {
      background-color: transparent !important;
      color: color(primary);
      &:hover {
        color: color(primary);
      }
    }
    .ant-select-item-option-active {
      background-color: rgba(243, 112, 33, 0.15) !important;
    }
    .ant-select-item-option-selected {
      background-color: rgba(243, 112, 33, 0.15) !important;
    }
  }
}
.ant-picker {
  width: 100%;
  padding: 10px 11px;
}
