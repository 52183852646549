.jobs-page {
  .ant-table-thead {
    th {
      &:nth-child(2) {
        min-width: 300px;
      }
      &:nth-child(5) {
        min-width: 200px;
      }
    }
  }
}
