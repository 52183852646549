.rec-vmo-table {
  height: 100%;
  .ant-table {
    background-color: transparent;
    color: color(text-color);
    font-size: 14px;
    .ant-table-thead {
      tr {
        th {
          background-color: #d9d9d9;
          font-size: 14px;
          font-weight: 700;
          color: color(text-color);
          border-bottom: none;
          padding: 16px 12px;
          border-color: #f2f2f2;
          white-space: nowrap;
          .ant-table-column-sort {
            background: color(background-primary);
          }
          .ant-table-column-sorter {
            color: rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
    .ant-table-tbody {
      tr {
        &.ant-table-placeholder {
          &:hover {
            td {
              background: transparent;
            }
          }
        }
        td {
          border-bottom: 1px solid #f2f2f2;
          border-right: 1px solid #f2f2f2 !important;
          padding: 16px 12px;
          background-color: white;
          &:last-child {
            border-right: none;
          }
          &.ant-table-column-sort {
            background: transparent;
            &.ant-table-cell-row-hover {
              background: 2;
              color: color(text-color);
            }
          }
        }
        .ant-table-cell-row-hover {
          background: rgb(255, 238, 228);
          color: color(text-color);
          .rec-link {
            color: white;
          }
        }
      }
    }
    .ant-table-content {
      overflow-x: auto;
      overflow-y: hidden;
      @include customScrollTrack(8px, color(background-secondary), 4px);
      border-radius: 10px 10px 0 0;
    }
  }
  // Empty box center
  &--empty {
    .ant-spin-nested-loading {
      height: 100%;
      .ant-spin-container {
        height: 100%;
        .ant-table {
          height: 100%;
          .ant-table-container {
            height: 100%;
            .ant-table-content {
              height: 100%;
              table {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.rec-vmo-box-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 700;
    color: white;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    color: color(text-grey);
  }
}
