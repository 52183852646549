.rec-vmo-notification {
  display: flex;
  align-items: center;
  &__icon {
    color: color(text-default);
    position: relative;
    padding: 0;
    width: 22px;
    height: 22px;
    &:hover,
    &:focus {
      color: color(text-default);
    }
    .is-new-notification {
      position: absolute !important;
      top: -2px;
      right: -1px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #fd5047;
    }
  }
  &__list {
    z-index: 1000001;
    padding-top: 6px;
    width: 500px;
    @include respond-down(sm) {
      width: calc(100vw - 24px);
      max-width: calc(100vw - 24px);
      top: 60px !important;
      left: 12px !important;
    }
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner {
      background: color(background-primary);
      border: 2px solid color(border-primary);
      .ant-popover-inner-content {
        padding: 24px 0;
      }
    }
  }
  &__detail {
    width: 100%;
    height: 100%;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;
      p {
        color: white;
        font-size: 14px;
        font-weight: 700;
      }
      button {
        display: flex;
        align-items: center;
        color: color(text-grey);
        font-size: 14px;
        font-weight: 600;
        &:hover,
        &:focus {
          color: color(text-grey);
        }
        svg {
          margin-right: 8px;
        }
        &.is-un-read {
          color: color(primary);
        }
      }
    }
    .content {
      margin-top: 24px;
      &.detail {
        margin-top: 0;
        height: 100%;
      }
      .item {
        padding: 16px 28px;

        .title {
          color: color(text-grey);
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .time {
          font-size: 12px;
          color: color(text-grey);
          margin-top: 4px;
          opacity: 0.4;
        }
        &:hover {
          cursor: pointer;
          background-color: color(primary);
          .title {
            color: color(text-color);
          }
          .time {
            color: color(text-color);
            opacity: 1;
          }
        }
        &.two-line {
          .title {
            color: color(text-grey);
            font-size: 14px;
            font-weight: 400;
            @include lineEllipsis(24px, 2);
            white-space: normal;
          }
        }
        &.un-read {
          position: relative;
          &::before {
            position: absolute;
            top: 22px;
            left: 12px;
            width: 8px;
            height: 8px;
            content: "";
            background-color: color(primary);
            border-radius: 50%;
          }
          &:hover {
            .title {
              color: color(text-color);
            }
            &::before {
              background-color: #ffdc59;
            }
          }
          .title {
            color: color(text-default);
          }
          .time {
            opacity: 1;
          }
        }
      }
      .view-all {
        padding-left: 28px;
        margin-top: 24px;
        a {
          font-size: 16px;
          font-weight: 600;
          text-decoration: underline;
        }
      }
      .rec-vmo-table {
        thead {
          display: none;
        }
        tbody {
          tr {
            td {
              padding: 0 !important;
              @include respond-down(sm) {
                white-space: inherit;
              }
              .item {
                position: relative;
                &::after {
                  position: absolute;
                  top: 100%;
                  left: 28px;
                  width: calc(100% - 48px);
                  height: 1px;
                  background-color: color(border-primary);
                  content: "";
                }
                .title {
                  @include respond-down(sm) {
                    white-space: inherit;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 16px;
    color: color(text-default);
    @include respond-down(sm) {
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
    }
    .ant-checkbox-wrapper {
      color: color(text-default);
      font-size: 16px;
      font-weight: 600;
      @include respond-down(sm) {
        width: 100%;
        margin-bottom: 12px;
      }
      .ant-checkbox {
        top: 2px;
      }
      &.ant-checkbox-wrapper-disabled {
        span {
          color: color(text-grey);
        }
        .ant-checkbox-inner {
          background-color: color(text-grey);
          border-color: color(text-grey) !important;
          &::after {
            border-color: color(text-grey);
          }
        }
      }
    }
    button {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      @include respond-down(sm) {
        padding: 0;
        margin-bottom: 12px;
      }
      &.mark-read {
        color: color(primary);
      }
      &.delete {
        color: color(error-primary);
      }
      &:disabled {
        color: color(text-grey) !important;
      }
      svg {
        margin-right: 10px;
      }
    }
  }
}

.ant-notification {
  z-index: 10000001;
  .ant-notification-notice {
    &.ant-notification-notice-icon-success {
      color: color(green-primary);
    }

    background-color: color(background-secondary);
    .ant-notification-notice-message {
      font-size: 16px;
      font-weight: 600;
      color: color(green-primary);
    }
    &.ant-notification-notice-error {
      .ant-notification-notice-message {
        color: color(error-primary);
      }
    }
    .ant-notification-notice-description,
    .ant-notification-notice-close {
      color: color(text-grey);
    }
  }
}
