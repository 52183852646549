.btn {
  height: 44px;
  width: 128px;
  font-size: 14px;
  font-weight: 700;
  text-shadow: none;
  color: color(text-color);
  &--outline {
    background-color: transparent;
    color: color(primary);
    &:hover,
    &:focus {
      background-color: transparent;
      color: color(primary);
    }
    &.ant-btn-dangerous {
      color: #ff4d4f;
    }
  }
  &--fill {
    color: white;
    &:hover,
    &:focus {
      color: color(text-color);
    }
  }
  &--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 12px;
    }
  }
  &-w-auto {
    width: auto;
  }
}

.rec-switch {
  height: 24px;
  &:focus {
    box-shadow: none;
  }
  background-color: color(background-secondary-bold);
  .ant-switch-handle {
    top: 3px;
    &::before {
      background-color: color(text-default);
    }
  }
  &.ant-switch-checked {
    background-color: color(primary);
    &:focus {
      box-shadow: none;
    }
    .ant-switch-handle {
      top: 3px;
      &::before {
        background-color: white;
      }
    }
  }
}

.radio-group-actions {
  margin-bottom: 16px;
  .ant-radio-button-wrapper {
    margin-right: 12px;
    background-color: color(background-secondary-bold);
    border-radius: 4px;
    color: color(text-grey);
    border: 1px solid transparent;
    &::before {
      display: none;
    }
    &.ant-radio-button-wrapper-checked {
      color: color(primary);
      border: 1px solid color(primary);
    }
  }
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  background-color: #d9d9d9 !important;
  opacity: 0.8;
}
