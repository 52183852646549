.captcha-robot {
  > div {
    width: 100% !important;
  }
  iframe {
    width: 100% !important;
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;

    .rc-anchor-dark.rc-anchor-normal {
      border: none !important;
    }
  }
}
.rc-anchor-normal {
  border: none !important;
}

// .g-recaptcha {
//   transform: scale(0.5);
//   transform-origin: 0 0;
// }
