.file-view {
  padding: 12px;
  background-color: #d9d9d9;
  border-radius: 8px;
  &__bottom {
    margin-top: 16px;
  }
  .page {
    font-weight: 700px;
    font-size: 14px;
  }
}
