.preview-image-table {
  cursor: pointer;
  text-align: center;
  .preview-image-thumbnail {
    object-fit: cover;
  }
}

.view-image-large {
  padding: 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.job-detail {
  &__title {
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: #f37021;
  }

  &__levels {
    margin-top: 8px;
  }

  &__prop-container {
    margin-top: 16px;
  }
  &__prop {
    font-weight: bold;
  }
}
