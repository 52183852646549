.candidates-table {
  .rec-vmo-table .ant-table .ant-table-thead tr th:nth-child(2) {
    min-width: 150px;
  }
}

.candidate-details {
  &__heading {
    font-weight: bold;
    font-size: 24px;
  }
  &__props {
    margin-top: 20px;
  }
}

.detail-name {
  font-weight: bold;
}

.title-link {
  cursor: pointer;
  &:hover {
    color: color(primary);
  }
}
