.rec-vmo-breadcrumb {
  margin-bottom: 6px;
  &__link-old {
    font-size: 14px;
    color: color(text-grey);
  }
  &__link-current {
    font-size: 14px;
    color: #b3b3b3;
  }
}
