.rec-vmo-pagination {
  margin: 24px 0 0 0 !important;
  align-items: center;
  .ant-pagination-options {
    position: absolute;
    left: 0;
    margin-left: auto;
    .ant-select {
      width: 200px;
      .ant-select-selector {
        height: 44px !important;
        display: flex;
        align-items: center;
        background-color: white !important;
        border: 1px solid #d9d9d9 !important;
        padding: 0 13px !important;
        &:hover {
          border: 1px solid color(primary) !important;
        }

        .ant-select-selection-search-input {
          height: 44px !important;
        }
        .ant-select-selection-item {
          display: flex;
          align-items: center;
          font-weight: 400;
          color: color(text-color) !important;
          font-size: 14px;
        }
      }
      .ant-select-arrow {
        color: color(text-color);
      }
      &-dropdown {
        background-color: white;

        .ant-select-item {
          padding: 10px 12px !important;
          color: color(text-color);
          &:hover {
            background-color: color(primary);
            color: color(text-color);
          }
          &:first-child {
            border-radius: 8px 8px 0 0;
          }
          &:last-child {
            border-radius: 0 0 8px 8px;
          }
        }
        .ant-select-item-option-selected {
          background-color: transparent !important;
          color: color(primary);
          &:hover {
            color: color(primary);
          }
        }
        .ant-select-item-option-active {
          background-color: color(primary) !important;
          color: color(text-color) !important;
        }
      }
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: transparent;
    color: color(text-pagination);
    font-family: "Lato";
  }
  .ant-pagination-item {
    width: 32px;
    height: 32px;
    background-color: transparent;
    border: 1px solid transparent;
    color: color(text-pagination);
    font-family: "Lato";
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;

    a {
      display: flex;
      align-items: center;
      height: 32px;
      width: 31px;
      justify-content: center;
      padding: 0;
      color: color(text-pagination);
      font-size: 14px;
      font-weight: 600;
    }
    &:hover {
      border: 1px solid color(primary);
      a {
        color: color(primary);
      }
    }
    &.ant-pagination-item-active {
      border: 1px solid color(primary);
      color: color(primary);
      a {
        color: color(primary);
      }
    }
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: color(text-pagination);
  }
  li {
    .ant-pagination-item-link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      white-space: nowrap;
    }
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background-color: transparent;
    border: 1px solid transparent;
    color: color(text-color);
    font-size: 14px;
  }
  .ant-pagination-disabled {
    opacity: 0.3;
  }
}
