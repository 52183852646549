/* ==========================================================================
   # START FUNCTION SETTING
   ==========================================================================
*/

/*Start build Color*/
@function color($color-name) {
  @return var(--color-#{$color-name});
}

/*End build Color*/

/*Start map_get variable css*/
@function get-map($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

/*End map_get variable css*/

/* ==========================================================================
   # END FUNCTION SETTING
   ==========================================================================
*/
